import React from "react";
import SEO from "../components/common/layout/seo";
import Layout from "../components/common/layout/layout";
import { Navigation } from "../components/common/navigation/navigation";
import { Pricing } from "../components/sections/pricing/pricing";
import { pricingSEO } from "../utils/seoText";
import { Footer } from "../components/sections/footer";

export const PricingPage = () => {
  return (
    <Layout>
      <SEO {...pricingSEO} />
      <Navigation />
      <Pricing />

      <Footer />
    </Layout>
  );
};

export default PricingPage;
